<template>
  <v-container fluid>
    <FormCard
      ref="pswResetForm"
      title="Aggiornamento Password"
      title_icon="mdi-account"
      class="mb-5"
      @submit="onPwdSubmit"
      @reset="onReset"
    >
      <p v-if="currentUser.is_temp_password">
        Per procedere è necessario aggiornare la password temporanea.
      </p>
      <v-row>
        <FormItem
          v-model="password_to_send"
          label="Password"
          rules="required|pwd_len|pwd_schars|pwd_chars|pwd_num|pwd_caps|confirm:@password_confirm"
          name="password"
          type="password"
          :cols="6"
          :sm="6"
          :md="6"
          :lg="6"
          :xl="6"
        />
        <FormItem
          v-model="password_confirm"
          vprovname="confirmation"
          rules="required|pwd_len|pwd_schars|pwd_chars|pwd_caps|pwd_num"
          label="Conferma password"
          name="password_confirm"
          type="password"
          :cols="6"
          :sm="6"
          :md="6"
          :lg="6"
          :xl="6"
        />
      </v-row>
    </FormCard>

    <FormCard
      v-if="!currentUser.is_temp_password"
      title="Dati personali"
      title_icon="mdi-account"
      class="mb-5"
      @submit="onInfoSubmit"
      @reset="onInfoReset"
    >
      <v-row>
        <FormItem
          v-model="first_name"
          rules="required"
          name="Nome"
          :readonly="!!currentCustomerId"
          :cols="12"
          :sm="12"
          :md="5"
          :lg="5"
          :xl="4"
        />

        <FormItem
          v-model="last_name"
          rules="required"
          name="Cognome"
          :readonly="!!currentCustomerId"
          :cols="12"
          :sm="12"
          :md="5"
          :lg="5"
          :xl="4"
        />
      </v-row>
      <v-row>
        <FormItem
          v-model="email"
          rules="email"
          name="Email"
          type="email"
          :cols="12"
          :sm="12"
          :md="4"
          :lg="3"
          :xl="3"
        />
        <FormItem
          v-model="work_phone_number"
          rules="required"
          name="Telefono"
          :readonly="!!currentCustomerId"
          :cols="12"
          :sm="12"
          :md="4"
          :lg="3"
          :xl="2"
        />
        <FormItem
          v-model="mobile_phone_number"
          rules="required"
          name="Cellulare"
          :readonly="!!currentCustomerId"
          :cols="12"
          :sm="12"
          :md="4"
          :lg="3"
          :xl="2"
        />
      </v-row>
    </FormCard>

    <BaseTable
      v-if="!currentUser.is_temp_password"
      title="Accessi e dispositivi"
      :headers="headers"
      module="auth"
    >
      <template v-slot:item.last_access_time="{ item }">
        {{ item.last_access_time | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>
    </BaseTable>
  </v-container>
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import FormCard from '@components/forms/FormCard.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import BaseTable from '@components/structure/base-table.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'auth/getCurrent',
  mutationType: 'auth/SET_CURRENT_FIELDS',
})

export default {
  page: {
    title: 'Profilo',
  },
  components: {
    FormItem,
    FormCard,
    BaseTable,
  },
  data: () => ({
    password_confirm: null,
    password_to_send: null,
    headers: [
      {
        text: 'Sistema Operativo',
        value: 'os_name',
      },
      {
        text: 'Versione',
        value: 'os_version',
      },
      {
        text: 'Descrizione',
        value: 'device_model',
      },
      {
        text: 'Indirizzo IP',
        value: 'last_access_ip',
      },
      {
        text: 'Ultimo accesso',
        value: 'last_access_time',
      },
      {
        text: 'Primo accesso',
        value: 'created_at',
      },
    ],
  }),
  computed: {
    ...authComputed,
    ...mapState('auth', {
      currentUser: (state) => state.current.user,
    }),
    ...mapFields([
      'user.username',
      'user.first_name',
      'user.last_name',
      'user.email',
      'user.mobile_phone_number',
      'user.work_phone_number',
    ]),
  },
  methods: {
    ...mapActions('auth', [
      'updatePassword',
      'update',
      'validate',
      'setCurrent',
    ]),
    ...mapMutations('auth', {
      set: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),
    async onPwdSubmit() {
      try {
        await this.updatePassword(this.password_to_send)

        this.$refs.pswResetForm.clear()

        this.$dialog.notify.success(
          'La password è stata modificata con successo.'
        )

        if (this.currentUser.is_temp_password) {
          await this.$router.push({ name: 'home' })
        }
      } catch (err) {
        await this.$dialog.notify.warning(
          err.message
            ? 'Si è verificato un errore: ' + err.message
            : "Si è verificato un errore con l'aggiornamento della password"
        )
      }
    },
    onReset() {
      this.password_to_send = null
      this.password_confirm = null
    },
    async onInfoSubmit() {
      const user = await this.update()
      this.set(user)
      // User is validated only for the added benefit of
      // updating the version stored in localStorage
      await this.validate()
    },
    async onInfoReset() {
      this.resetCurrent()
      this.setCurrent()
    },
  },
}
</script>
